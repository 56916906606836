import React from "react";
import { graphql } from 'gatsby';
import Nav from '../components/Nav'
import Hero from '../components/Hero';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Task from '../images/task.svg';
import App from '../images/app.svg';
import Js from '../images/js.svg';
import Cloud from '../images/cloud.svg';
import Study from '../images/study.svg';
import SEO from '../components/seo/SEO';

const About = ({ data }) => {
    return (
        <>
        <SEO/>
            <div className="gradient">
                <Nav active="about" />
                <Hero
                    heading={data.site.siteMetadata.aboutMain}
                    subText={data.site.siteMetadata.aboutDescription}
                    subHeading={data.site.siteMetadata.aboutSubMain}
                    buttonText="Plus sur mon profil"
                    half={true}
                    isSvg={true}
                    image={Task}
                    handleClick={() => {
                        const element = document.getElementById('profile');
                        if (element) element.scrollIntoView();
                    }}
                />

            </div>

            <section className="bg-white py-8 container mx-auto m-8" id="profile">
                <div className="max-w-5xl">
                    <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">Mon quotidien</h1>
                    <div className="w-full mb-4">
                        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>

                    <div className="flex flex-wrap items-center justify-center">
                        <div className="w-5/6 sm:w-1/2 p-6">
                            <h3 className="text-3xl text-purple font-bold leading-none mb-3">Application mobiles et IOT</h3>
                            <p className="text-gray-600 mb-8">Une grande partie de mon temps est passée à développer des applications répondants à des besoins divers et variés.<br /><br />
                                Ces application sont développées en Javascript à l'aide de React native, ainsi que Cordova pour les applications un peu plus anciennes.
                            </p>
                            <p className="text-gray-600 mb-8">
                                Je développe aussi dans le cadre des objets connectés (plus précisément de la domotique), des systèmes en temps réels lié à différents éléments d'un foyer.
                            </p>

                        </div>
                        <div className="w-full sm:w-1/2 p-6">
                            <img className="w-full sm:h-64 mx-auto" src={App} alt="Femme utilisant une applications mobile"/>
                        </div>
                    </div>


                    <div className="flex flex-wrap items-center justify-center flex-col-reverse sm:flex-row">
                        <div className="w-full sm:w-1/2 p-6 mt-6">
                            <img className="w-full sm:h-64 mx-auto" src={Js} alt="Femme assise sur le logo react js" />
                        </div>
                        <div className="w-full sm:w-1/2 p-6 mt-6">
                            <div className="align-middle">
                                <h3 className="text-3xl text-purple font-bold leading-none mb-3">Frameworks Javascript</h3>
                                <p className="text-gray-600 mb-8">
                                    Que ce soit pour le backend ou le frontend, mes principaux outils que j'utilise sont en Javascript, un language qui a certes ses défaults, mais qui a sû se montrer incontournable dans le domaine du web.
                                </p>
                                <p className="text-gray-600 mb-8">
                                    Pour les curieux, voilà les outils que j'utilise :
                                </p>
                                <ul className="text-gray-600 mb-8">
                                    <li>Node.js</li>
                                    <li>React</li>
                                    <li>Gatsby</li>
                                    <li>Express</li>
                                    <li>Hapi</li>
                                    <li>Mosca</li>
                                </ul>
                                <p className="text-gray-600 mb-8">
                                    Je ne pratique pas seulement le Javascript, mais aussi d'autres languages et frameworks tels que :

                                </p>
                                <ul className="text-gray-600 mb-8">
                                    <li>Go</li>
                                    <li>PHP</li>
                                    <li>Symfony</li>
                                    <li>Laravel</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-center">
                        <div className="w-5/6 sm:w-1/2 p-6">
                            <h3 className="text-3xl text-purple font-bold leading-none mb-3">Cloud</h3>
                            <p className="text-gray-600 mb-8">Le cloud occupe aujourd'hui une partie non négligeable de mon temps. Sur celui ci, je conçois, maintiens et améliore des services, applications tout en minimisant les frais ainsi que le temps de gestion des infrastructure.
                            </p>
                            <p className="text-gray-600 mb-8">
                                De mon point de vue, le cloud est un virage non négligeable permettant de s'affranchir des limites des serveurs, ainsi que de gagner en vélocité dans les développement.
                            </p>
                            <p className="text-gray-600 mb-8">
                                La conquête du cloud est donc une chose très important de mon coté.
                            </p>
                        </div>
                        <div className="w-full sm:w-1/2 p-6">
                            <img className="w-full sm:h-64 mx-auto" src={Cloud} alt="Fusée représentant le cloud"/>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-center flex-col-reverse sm:flex-row">
                        <div className="w-full sm:w-1/2 p-6 mt-6">
                            <img className="w-full sm:h-64 mx-auto" src={Study} alt="Homme étudiant des concepts " />
                        </div>
                        <div className="w-full sm:w-1/2 p-6 mt-6">
                            <div className="align-middle">
                                <h3 className="text-3xl text-purple font-bold leading-none mb-3">Approfondissement des connaissances</h3>
                                <p className="text-gray-600 mb-8">
                                    Étant curieux de nature, j'aime découvrir les nouvelles méthodologies et technos tout juste sorties sur le marché.
                                </p>
                                <p className="text-gray-600 mb-8">
                                   Pour cela j'étudie, lie et pratique plusieurs fois par semaine pour rester à l'affût d'outils qui permetteraient de faciliter mon quotidien ou celui de mes clients.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Contact />
            <Footer image={data.footerImage.childImageSharp.fluid}/>
            
        </>
    )
}

export const query = graphql`
    query AboutpageQuery {
        allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "category"} } }
        sort: {order: DESC, fields: [frontmatter___date]}
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        path
                        date,
                        image {
                            publicURL
                            childImageSharp {
                                sizes(maxWidth: 1240 ) {
                                    srcSet
                                }
                            }
                        }
                    }
                }
            }
        },
        site {
            siteMetadata {
                aboutDescription,
                aboutMain,
                aboutSubMain
            }
        },
        footerImage: file(relativePath: {eq: "Logo.png"}) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`

export default About;
